import React from "react";

import { User as FirebaseUser } from "firebase/auth";
import {
  Authenticator,
  buildCollection,
  buildProperty,
  buildSchema,
  EntityReference,
  FirebaseCMSApp,
  NavigationBuilder,
  NavigationBuilderProps
} from "@camberi/firecms";

import "typeface-rubik";
import "typeface-space-mono";

// TODO: Replace with your config
const firebaseConfig = {
  apiKey: "AIzaSyDvqwX7N0dKgM-yKMrJm_quopOK6G2-SNg",
  authDomain: "ateliermomomo-host.firebaseapp.com",
  databaseURL: "https://ateliermomomo-host.firebaseio.com",
  projectId: "ateliermomomo-host",
  storageBucket: "ateliermomomo-host.appspot.com",
  messagingSenderId: "979234892778",
  appId: "1:979234892778:web:0496b6754daef41ef92093",
  measurementId: "G-6M1M4MS5Z6"
};

const locales = {
  "en-US": "English (United States)",
  "es-ES": "Spanish (Spain)",
  "de-DE": "German"
};



type Event = {
  eventId: string;
  eventName: string;
  eventDescription: string;
  dates: Date[];
  deepDescriptions: Array<{
    title: string;
    data: Array<{
      title: string;
      description: string;
      important: boolean;
    }>;
  }>
  descriptions: Array<{
    title: string;
    description: string;
    important: boolean;
  }>;
  reserveForm: string;
  backup: boolean;
  createdAt: Date;
}



const eventShema = buildSchema<Event>({
  name: "日付",
  defaultValues: {
    createdAt: () => new Date()
  },
  properties: {
    eventId: {
      title: "イベントID",
      description: "ここのIDがURLの一部になります",
      dataType: "string",
      validation: {
        required: true,
      },
    },
    eventName: {
      title: "イベント名",
      description: "イベントの名前です",
      dataType: "string",
      validation: {
        required: true
      }
    },
    eventDescription: {
      title: "イベントの説明",
      dataType: "string",
      validation: {
        required: true
      },
      config: {
        markdown: true
      }
    },
    dates: {
      title: "開催する日付",
      dataType: "array",
      validation: { required: true },
      of: {
        validation: { required: true },
        dataType: "timestamp"
      }
    },
    deepDescriptions: {
      title: "deepDescriptions",
      dataType: "array",
      of: {
        dataType: "map",
        properties: {
          title: {
            title: "title",
            dataType: "string",
            validation: { required: true }
          },
          data: {
            title: "data",
            dataType: "array",
            validation: { required: true },
            of: {
              dataType: "map",
              properties: {
                title: {
                  title: "title",
                  dataType: "string",
                  validation: { required: true }
                },
                description: {
                  title: "description",
                  dataType: "string",
                  validation: { required: true },
                  config: {
                    markdown: true
                  }
                },
                important: {
                  title: "important",
                  dataType: "boolean"
                }
              }
            }
          }
        }
      }
    },
    descriptions: {
      title: "descriptions",
      dataType: "array",
      of: {
        dataType: "map",
        properties: {
          title: {
            title: "title",
            dataType: "string",
            validation: { required: true }
          },
          description: {
            title: "description",
            dataType: "string",
            validation: { required: true },
            config: {
              markdown: true
            }
          },
          important: {
            title: "important",
            dataType: "boolean"
          }
        }
      }
    },
    reserveForm: {
      title: "申込みURL",
      dataType: "string",
      validation: {
        url: true
      }
    },
    createdAt: {
      title: "作成日時",
      dataType: "timestamp",
      validation: {
        required: true,
      },
      disabled: true
    },
    backup: {
      title: "backup",
      description: "バックアップ用．操作不可(できない)",
      dataType: "boolean",
      disabled: true
    }
  }
})



export default function App() {

  const navigation: NavigationBuilder = async ({
    user,
    authController
  }: NavigationBuilderProps) => {

    return ({
      collections: [
        buildCollection({
          path: "events",
          schema: eventShema,
          name: "開催する日付",
          permissions: ({ authController }) => ({
            edit: true,
            create: authController.extra.roles.includes("admin"),
            // we have created the roles object in the navigation builder
            delete: authController.extra.roles.includes("admin")
          })
        })
      ]
    });
  };

  const myAuthenticator: Authenticator<FirebaseUser> = async ({
    user,
    authController
  }) => {
    // You can throw an error to display a message
    console.log(user?.email);
    if (user === null) {
      return false;
    }
    if (user.email === null) {
      return false;
    }
    if (!["ateliermomomo@gmail.com", "contact.bonychops@gmail.com"].includes(user.email)) {
      return false;
    }

    console.log("Allowing access to", user?.email);
    // This is an example of retrieving async data related to the user
    // and storing it in the user extra field.
    const sampleUserData = await Promise.resolve({
      roles: ["contact.bonychops@gmail.com"].includes(user.email || "") ? ["admin"] : []
    });

    authController.setExtra(sampleUserData);
    return true;
  };

  return <FirebaseCMSApp
    name={"ateliermomomo-cms"}
    authentication={myAuthenticator}
    navigation={navigation}
    firebaseConfig={firebaseConfig}
  />;
}